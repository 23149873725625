import React from "react";
import { Section, Text } from "@/components";

export const DataSecuritySection = (): JSX.Element => {
  // Security features data for mapping
  const securityFeatures = [
    {
      id: 1,
      text: "Powered by Microsoft Azure OpenAI Enterprise which does NOT interact with Chat GPT or other Microsoft products",
    },
    {
      id: 2,
      text: "Your data is NOT used to train, retrain, or improve Azure Open AI foundation models",
    },
    {
      id: 3,
      text: "We are working toward SOC 2 compliance",
    },
  ];

  return (
    <Section className="gap-10 md:gap-16 max-w-[500px] md:max-w-[1131px]">
      <div className="relative rounded-[10px] overflow-hidden border-none">
        <div className="p-0">
          <div
            className="flex w-[355px] md:w-[481px] h-[342px] md:h-[463px] bg-[100%_100%]"
            style={{
              backgroundImage: "url('/images/home/data-security-background.png')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              className="w-[210px] md:w-[285px] h-[248px] md:h-[336px] m-auto"
              alt="One one"
              src="/images/home/data-security.svg"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full md:w-[465px] items-start gap-4">
        <div className="flex flex-col items-start gap-4 w-full">
          <Text.Heading size="sm" className="gap-2">
            <span className="text-[#aee4e6]">DATA, TRAINING</span>
            <span className="text-white">, &amp; SECURITY</span>
          </Text.Heading>

          <Text.Description size="lg" className="font-normal text-[#D7D7D7]">
            Built exclusively for your private use; we understand our
            customer&apos;s data is sensitive and not fit for public access
          </Text.Description>
        </div>

        <div className="flex flex-col items-start gap-4 md:gap-6 w-full">
          {securityFeatures.map((feature) => (
            <div key={feature.id} className="flex gap-3 w-full">
              <img src="/images/home/circle-check.svg" alt="Check icon" className="w-5 h-5 mt-1" />
              <Text.Description className="w-[380px] font-light text-[#FEFEFE] text-[16px] md:text-[18px]">
                {feature.text}
              </Text.Description>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};
