import { ReactComponent as LogoSvg } from "@/assets/svg/logo.svg";
import { Button, Link } from "./Buttons";
import { useApp } from "@/hooks";
import { ReactComponent as HamburgerSvg } from "@/assets/svg/icon--hamburger.svg";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

export const Header = () => {
  const [isOpened, setOpened] = useState(false);
  const { showContactSalesModal, setShowContactSalesModal } = useApp();
  const { screen } = useApp();
  const { pathname } = useLocation();

  useEffect(() => {
    setOpened(false);
  }, [screen, pathname]);

  const onToggleContactSalesModal = () => {
    setShowContactSalesModal(!showContactSalesModal);
  }

  if (screen === "") {
    return (
      <div className="sticky top-0 z-50">
        <div className="relative z-50 container mx-auto bg-[#191a1f]">
          <div className="flex items-center justify-between py-4">
            <Link className="!px-0" to="/">
              <LogoSvg className="h-auto" />
            </Link>

            <button className="hamburger" onClick={() => setOpened(!isOpened)}>
              <HamburgerSvg />
            </button>
          </div>
        </div>

        <div className="absolute z-10">
          <Transition show={isOpened} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpened}>
              <div className="fixed inset-0" />

              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 top-[85px]">
                    <TransitionChild
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500"
                      enterFrom="-translate-y-full"
                      enterTo="translate-y-[0px]"
                      leave="transform transition ease-in-out duration-500"
                      leaveFrom="translate-y-[0px]"
                      leaveTo="-translate-y-full"
                    >
                      <DialogPanel className="pointer-events-auto w-screen max-w-md">
                        <div className="bg-third shadow-xl rounded-b-2xl">
                          <div className="flex flex-col items-center p-8 gap-5">
                            <Button
                              className="w-52"
                              href="https://app.intelliroom.ai"
                            >
                              Login
                            </Button>

                            <Button
                              className="w-52"
                              href="https://app.intelliroom.ai/auth/sign-up"
                            >
                              Sign up
                            </Button>
                          </div>
                        </div>
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full sticky top-0 z-50 bg-[#17181d]">
        <div className="container mx-auto">
          <div className="flex items-center justify-between py-4">
            <Link className="!px-0" to="/">
              <LogoSvg className="h-auto" />
            </Link>

            <div className="flex items-center gap-2.5">
              <Button
                className="h-[51px] px-5 bg-transparent text-secondary"
                onClick={onToggleContactSalesModal}
              >
                Contact Sales
              </Button>

              <div className="flex gap-2.5 border rounded border-btn-border p-1.5">
                <Button
                  href="https://app.intelliroom.ai"
                  className="h-[38px] !px-5 !py-1.5 bg-transparent text-white border-none"
                >
                  Login
                </Button>
                <Button
                  href="https://app.intelliroom.ai/auth/sign-up"
                  className="h-[38px] !px-5 !py-1.5 !border-none"
                >
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
