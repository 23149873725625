import {
  Banner,
  InsightSection,
  IntelliroomDifferenceSection,
  KeyBenefitsSection,
  PricingSection,
  DataSecuritySection,
  HowItWorksSection,
  GetStartedSection,
  MobileAppSection,
} from "./sections/index";

export const HomePage = () => {
  return (
    <div className="flex flex-col">
      <div className="relative container mx-auto">
        <Banner />
      </div>
      
      <InsightSection />

      <IntelliroomDifferenceSection />

      <MobileAppSection />
      
      <KeyBenefitsSection />

      <PricingSection />

      <HowItWorksSection />

      <DataSecuritySection />

      <GetStartedSection />

    </div>
  );
};
