import React from "react";
import { Button, Section, Text } from "@/components";
import { useApp } from "@/hooks";

export const KeyBenefitsSection = (): JSX.Element => {
  const { setShowContactSalesModal, doGetStarted } = useApp();
  // Data for benefits cards
  const benefits = [
    {
      id: 1,
      title: "Smarter",
      description:
        "With targeted, actionable data, investors can make higher ROI decisions.",
      icon: "/images/home/magic-wand.gif",
      alt: "Wired outline magic wand",
    },
    {
      id: 2,
      title: "Faster",
      description:
        "Streamlining diligence helps investors seize opportunities with speed & confidence.",
      icon: "/images/home/outline-clock.gif",
      alt: "Wired outline clock",
    },
    {
      id: 3,
      title: "More Profitable",
      description:
        "Scouring the web, data rooms, and social media uncovers hidden details that traditional diligence misses—maximizing returns",
      icon: "/images/home/dividends-pinch.gif",
      alt: "Wired outline dividends",
    },
  ];

  return (
    <Section className="gap-10 md:gap-16 px-5">
      <div className="flex flex-col w-full max-w-[528px] items-start gap-10">
        <div className="flex flex-col items-center md:items-start gap-6">
          <h2 className="font-heading-main text-center flex gap-2">
            <Text.Heading className="text-white">Key</Text.Heading>
            <Text.Heading className="text-[#aee4e6]">Benefits</Text.Heading>
          </h2>

          <Text.Description className="max-w-[453px] text-[#c5c5c5]">
            The IntelliRoom shines light on blind spots and equips investors
            with the tools to make faster, smarter, and more profitable
            decisions:
          </Text.Description>
        </div>

        <div className="items-start gap-5 w-full hidden sm:flex">
          <Button
            className="flex-1 h-[60px] rounded font-semibold text-base"
            onClick={doGetStarted}
          >
            Get Started Today
          </Button>

          <Button
            secondary
            className="flex-1 h-[60px] rounded font-semibold text-base"
            onClick={() => setShowContactSalesModal(true)}
          >
            Contact Sales
          </Button>
        </div>
      </div>

      <div className="flex flex-col w-full max-w-[539px] gap-8">
        {benefits.map((benefit) => (
          <div key={benefit.id} className="bg-transparent border-none">
            <div className="flex items-center gap-5 p-0">
              <img
                className="w-[86px] h-[86px] object-cover"
                alt={benefit.alt}
                src={benefit.icon}
              />
              <div className="flex flex-col items-start gap-3.5">
                <Text.Description size="lg" className="text-[#fdfdfd] text-[20px] sm:text-[20px] font-semibold">
                  {benefit.title}
                </Text.Description>
                <Text.Description className="text-[#c5c5c5] text-[16px] sm:text-[18px]">
                  {benefit.description}
                </Text.Description>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-start gap-5 mx-auto sm:hidden">
        <Button
          className="flex-1 h-[60px] min-w-[280px]"
          onClick={doGetStarted}
        >
          Get Started Today
        </Button>

        <Button
          secondary
          className="flex-1 h-[60px] min-w-[280px]"
          onClick={() => setShowContactSalesModal(true)}
        >
          Contact Sales
        </Button>
      </div>
    </Section>
  );
};
