import { FaArrowRight } from "react-icons/fa";
import { Button, Text } from "@/components";
import { useApp } from "@/hooks";
export const Banner = () => {
  const { goToPricingSection } = useApp();

  return (
    <>
      <div
        className="flex flex-col justify-center items-center mt-[40px] md:mt-[80px] py-5 gap-2.5 overflow-hidden"
        style={{
          backgroundImage: "url('/images/home/home-banner.svg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Text.Heading size="md" className="sm:block max-w-[1200px] text-center text-secondary text-[28px] sm:text-[44px] 2xl:max-w-[100%]">
          The Future of Due Diligence.
        </Text.Heading>

        <div className="flex text-center gap-x-2.5 text-white font-medium ">
          <Text.Heading size="lg">Delivered Today.</Text.Heading>
        </div>

        <div className="text-center text-[16px] md:text-[24px] w-full max-w-[300px] md:max-w-[757px]">
          Collaborative AI due diligence for institutional investors.<br />Delivering smarter, faster, and more profitable investment decisions.
        </div>

        <div className="block w-full max-w-[900px] mt-7">
          <div className="flex flex-col md:flex-row items-center md:justify-center mb-2">
            <Button onClick={goToPricingSection} className="flex items-center">
              Pricing & Plans
            </Button>
          </div>
        </div>
      </div>

      <div className="w-full mx-auto max-w-[383px] md:max-w-[1120px]">
        <img
          className="hidden md:block w-full h-auto object-cover"
          alt="New diligence flags"
          src="/images/home/new--diligence-flags-web-x3.png"
        />
        <img
          className="block md:hidden w-full h-auto object-cover"
          alt="New diligence flags"
          src="/images/home/new--diligence-flags-web.png"
        />
      </div>
    </>
  );
};
