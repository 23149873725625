import { Button } from "@/components";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      className="w-screne min-h-screen h-fit relative flex items-center justify-center"
      style={{ backgroundImage: "url('/images/bg-grid.webp')" }}
    >
      <div className="absolute top-[10%] left-[20%] orange-sun" />

      <div className="">
        <div className="text-center text-[10rem] font-medium mb-5 leading-none">
          404
        </div>
        <div className="text-center text-[50px] font-medium">
          Something's not right.
        </div>
        <div className="text-center my-4">
          We can’t find the page your are looking for.
        </div>

        <div className="flex items-center justify-center py-4">
          <Button secondary onClick={() => navigate("/", { replace: true })}>
            Back to homepage
          </Button>
        </div>
      </div>
    </div>
  );
};
