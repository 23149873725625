import clsx from "clsx";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

type HeadingProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    size?: "md" | "sm" | "lg";
  }
>;
export const Heading: FC<HeadingProps> = ({
  className,
  children,
  size = "md",
  ...props
}) => {
  return (
    <div
      className={clsx(
        "leading-tight font-bold",
        {
          "text-[28px] md:text-[44px]": size === "sm",
          "text-[33px] md:text-[54px]": size === "md",
          "text-[33px] md:text-[72px]": size === "lg",
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
