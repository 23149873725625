import React from "react";
import { Section, Text } from "@/components";

export const MobileAppSection = (): JSX.Element => {
  // Data for platform list to enable mapping
  const platforms = [
    { name: "iOS" },
    { name: "Android" },
    { name: "Telegram Mini App" },
  ];

  return (
    <Section
      className="rounded-[10px] overflow-hidden"
      style={{
        backgroundImage: "url('/images/home/analytics_background.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="relative w-full h-full max-w-[1131px] mx-auto mb-10 sm:mb-0">
        <div className="mx-auto flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 flex justify-center">
            <img
              className="hidden md:block w-auto h-[375px] sm:h-[556px] object-contain"
              alt="iPhone Pro mockup"
              src="/images/home/iphone-coming-soon.png"
            />
          
            <img
              className="block md:hidden w-auto h-[375px] sm:h-[556px] object-contain ml-[60px]"
              alt="iPhone Pro mockup"
              src="/images/home/iphone-coming-soon.png"
            />
          </div>

          <div className="md:w-1/2 flex border-none shadow-none bg-transparent">
            <div className="p-0 flex flex-col gap-5 sm:gap-10">
              <div className="flex flex-col">
                <Text.Heading size="md" className="text-[#000]">
                  COMING SOON
                </Text.Heading>
                <Text.Heading size="md" className="text-white">
                  Mobile App
                </Text.Heading>
              </div>

              <div className="flex flex-col gap-5">
                {platforms.map((platform, index) => (
                  <div key={index} className="flex items-center gap-[11px]">
                    <div className="relative w-5 h-5">
                      <div className="relative h-5">
                        <img
                          className="absolute w-5 h-5 top-0 left-0 text-black"
                          alt="CheckIcon icon"
                          src="/images/home/black-circle-check.svg"
                        />
                      </div>
                    </div>
                    <span className="font-light text-black text-2xl leading-8 font-['Inter',Helvetica]">
                      {platform.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
