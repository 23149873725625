import React from "react";
import { Section } from "@/components";

export const HowItWorksSection = (): JSX.Element => {
  // Data for the steps to enable mapping
  const steps = [
    {
      number: "Step 1",
      title: "Upload Investment PDF",
      description: "Support for additional file types coming soon",
    },
    {
      number: "Step 2",
      title: "Upload DD Checklist",
      description: "Bring your own or generate using a public LLM",
    },
    {
      number: "Step 3",
      title: "Get Answers w/ Flags",
      description: "Answers based on pitch deck data + internet search",
    },
    {
      number: "Step 4",
      title: "Deep Dive",
      description: "Research, write memos, & ask anything inside a private LLM",
    },
  ];

  return (
    <Section className="flex-col max-w-[1148px] mx-auto gap-10 md:gap-16">
      <div className="flex flex-col items-center max-w-[338px]">
        <h2 className="font-semibold text-[33px] sm:text-[54px] text-white text-center leading-[66px] font-['Inter',Helvetica] mt-[-1px]">
          How it works
        </h2>
        <p className="font-light text-lg md:text-xl text-[#fdfdfd] text-center leading-6 font-['Inter',Helvetica]">
          Step-by-Step DD Process For Any Private Market Investment
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
        {steps.map((step, index) => (
          <div
            key={index}
            className="max-w-[272px] bg-[#282d31] rounded border-b-2 border-[#aee4e6] border-solid"
          >
            <div className="flex flex-col items-start gap-6 p-5">
              <span className="font-semibold text-[28px] text-[#aee4e6] leading-[50px]">
                {step.number}
              </span>

              <div className="flex flex-col items-start gap-2 w-full">
                <h3 className="font-semibold text-xl text-white leading-5 font-['Inter',Helvetica] mt-[-1px]">
                  {step.title}
                </h3>
                <p className="font-normal text-base text-[#e3e3e3] leading-5 font-['Inter',Helvetica]">
                  {step.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};
