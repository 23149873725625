import { Link } from "react-router-dom";
import { Text, Section } from "@/components";
import { useApp } from "@/hooks";

export const GetStartedSection = () => {
  const { setShowContactSalesModal } = useApp();

  return (
    <Section id="get-started-section" className="flex-col items-center gap-2.5 overflow-hidden">
      <div className="border-t border-solid border-[#727070] pt-10 w-full max-w-[1148px]"></div>
      
      <div>
        <img src="/images/home/intelliroom-logo.svg" alt="Get Started" />
      </div>

      <div className="font-dmSans font-normal text-center w-full max-w-[300px] md:max-w-[800px] my-0 md:my-2.5">
        <Text.Description size="xl" className="hidden sm:block text-[16px] md:text-[24px]">
          Collaborative AI due diligence for institutional investors.<br />Delivering smarter, faster, and more profitable investment decisions.
        </Text.Description>
        <Text.Description size="sm" className="block sm:hidden">
          Collaborative AI due diligence for institutional investors. Delivering smarter, faster, and more profitable investment decisions.
        </Text.Description>
      </div>
      

      <div className="w-full md:max-w-[757px] flex justify-between px-12 my-5 md:my-2.5">
        <div className="flex hidden md:flex">
          <Link to="https://app.intelliroom.ai/auth/login" className="mr-4">Login</Link>
          
          <Link to="" className="cursor-pointer" onClick={() => setShowContactSalesModal(true)}>Contact Sales</Link>
        </div>

        <div className="flex block md:hidden">
          <Link to="https://app.intelliroom.ai/auth/login" className="mr-4 text-[#AEE4E6]">Login</Link>
          
          <Link to="" className="cursor-pointer text-[#AEE4E6]" onClick={() => setShowContactSalesModal(true)}>Contact Sales</Link>
        </div>

        <div className="flex">
          <Link to="https://x.com/t12_ai" className="mr-4">
            <img src="/images/home/x.svg" alt="X" />
          </Link>
          <Link to="https://www.linkedin.com/company/t12ai/">
            <img src="/images/home/linkedin.svg" alt="LinkedIn" />
          </Link>
        </div>
      </div>

      <div className="block w-full md:max-w-[757px] mt-7 px-12 border-b border-solid border-[#727070] pt-10">
        <div className="flex justify-between pb-7">
          <Text.Heading className="text-center text-secondary text-[24px] md:text-[34px]">
            Get Started
          </Text.Heading>
          <Link to="https://app.intelliroom.ai/auth/sign-up">
            <img src="/images/home/vector-up.svg" alt="Get Started" className="w-6 h-6 md:w-10 md:h-10" />
          </Link>
        </div>
      </div>
    </Section>
  );
};
