import React from "react";
import clsx from "clsx";
import { Section, Text } from "@/components";
import { SubSection } from "./SubSection";
import "./animation.css";

// Define feature data for mapping
const features = [
  {
    title: "High Level DD in Minutes",
    description:
      "Upload pitch PDF into data room, enter questions or upload spreadsheet with diligence checklist",
    imagePosition: "right",
    contentImage: "/images/home/diligence-flags-1.png",
    decorationImage: "/images/home/patent-pending.png",
  },
  {
    title: "Click for Analysis + Sources",
    description:
      "We scrape their pitch decks and scour the internet to provide in-depth source based analysis",
    imagePosition: "left",
    contentImage: "/images/home/diligence-flags-2.png",
  },
  {
    title: "Focus DD Where It Matters",
    description:
      "Filter for green flags (pro/bullish) or red flags (con/bearish) to prioritize due diligence efforts",
    imagePosition: "right",
    contentImage: "/images/home/diligence-flags-3.png",
  },
  {
    title: "Reduce Time to Learn",
    description:
      "Also included is a private LLM with a familiar UX that provides AI answers, deep dives, investment memos writing, and more.",
    imagePosition: "left",
    contentImage: "/images/home/diligence-flags-4.png",
  },
  {
    title: "The Intelligent Data Room",
    description:
      "Delivering accurate & reliable information with AI-powered data verification & alerts (coming soon)",
    imagePosition: "right",
    contentImage: "/images/home/diligence-flags-5.png",
  },
];

export const IntelliroomDifferenceSection = (): JSX.Element => {
  return (
    <>
      <Section 
        className="sticky-section"
        style={{
          backgroundImage: `url(/images/home/background-left.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
        }}
      >
        <div className="sticky-container">
          {features.map((feature, index) => (
            <div
              key={index}
              style={{ zIndex: index + 1 }}
              className={clsx("feature-section active", {
                "mt-0 md:mt-10": index !== 0
              })}
            >
              <div className="max-w-[1024px] xl:max-w-[1230px] items-center mx-auto px-0 xl:px-10 flex flex-col lg:flex-row items-center gap-6 md:gap-10 lg:gap-[108px] w-full">
                {feature.imagePosition === "left" && (
                  <div className="relative">
                    <div className="image-left">
                      <SubSection
                        contentImage={feature.contentImage}
                        imagePosition={feature.imagePosition}
                        decorationImage={feature.decorationImage}
                      />
                    </div>
                  </div>
                )}

                {feature.imagePosition === "right" && (
                  <div className="block lg:hidden relative">
                    <div className="image-left">
                      <SubSection
                        contentImage={feature.contentImage}
                        imagePosition={feature.imagePosition}
                        decorationImage={feature.decorationImage}
                      />
                    </div>
                  </div>
                )}

                <div className="flex-col w-[337px] lg:w-[542px] flex items-center gap-10 flex relative">
                  <div className={clsx("text-block", feature.imagePosition === "left" ? "left" : "right")}>
                    <Text.Heading size="sm" className="font-semibold text-white text-[28px] lg:text-[44px]">
                      {feature.title}
                    </Text.Heading>
                    <Text.Description className="mt-3 lg:mt-5 font-light text-[#FEFEFE] text-[16px] lg:text-[24px] leading-[28px] lg:leading-8 opacity-90">
                      {feature.description}
                    </Text.Description>
                  </div>
                </div>

                {feature.imagePosition === "right" && (
                  <div className="hidden lg:block relative">
                    <div className="image-right">
                      <SubSection
                        contentImage={feature.contentImage}
                        imagePosition={feature.imagePosition}
                        decorationImage={feature.decorationImage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </Section>
    </>
  );
};
