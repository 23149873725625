import React from "react";
import { Button, Section, Text } from "@/components";
import { useApp } from "@/hooks";

export const InsightSection = (): JSX.Element => {
  const { setShowContactSalesModal, goToPricingSection } = useApp();

  return (
    <Section className="flex-col max-w-[700px] items-center gap-10 mx-auto mt-5">
      <div className="flex flex-col items-center gap-7 w-full">
        <h2 className="w-full flex flex-col sm:flex-row justify-center items-center font-heading-main text-center text-[33px] sm:text-[44px]">
          <Text.Heading size="md" className="text-secondary">AI Insights. &nbsp;</Text.Heading>
          <Text.Heading size="md" className="text-white"> Human Profits.</Text.Heading>
        </h2>

        <div className="max-w-[702px] text-[16px] md:text-[24px] font-normal text-[#d6d6d6] text-center px-5 leading-[26px]">
          <p className="mb-3">
            In the near future every successful investor will leverage AI to
            amplify their capabilities.
          </p>
          <p className="mb-3">
            Market intelligence, due diligence, and deal screening won&apos;t just
            be easier with AI—they&apos;ll be more profitable.
          </p>
          <p>
            With IntelliRoom, that future is here today.
          </p>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center gap-3">
        <Button
          className="h-[60px] px-8 py-2.5 rounded"
          onClick={goToPricingSection}
        >
          Pricing &amp; Plans
        </Button>

        <Button
          secondary
          className="h-[60px] px-8 py-2.5 rounded"
          onClick={() => setShowContactSalesModal(true)}
        >
          Contact Sales
        </Button>
      </div>

      <h2 className="w-full flex flex-col sm:flex-row justify-center items-center font-heading-main text-center mt-[26px] md:mt-[40px]">
        <Text.Heading size="md" className="text-white">The IntelliRoom&nbsp;</Text.Heading>
        <Text.Heading size="md" className="text-secondary"> Difference</Text.Heading>
      </h2>
    </Section>
  );
};
