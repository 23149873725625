import React from "react";

// Component for feature images to avoid repetition
interface SubSectionProps {
  contentImage: string;
  imagePosition: string;
  decorationImage?: string;
}

export const SubSection = ({
  contentImage,
  imagePosition,
  decorationImage,
}: SubSectionProps) => {
  return (
    <div className="relative w-[337px] md:w-[400px] xl:w-[481px] h-auto bg-[#52696D] rounded-[10px] border-none">
      <div className="relative p-0 py-0">
        <div className="relative w-full h-full">
          <img
            className={`relative z-1 ${
              imagePosition === "left"
                ? "w-full h-full"
                : contentImage.includes("dataroom")
                  ? "w-full h-full"
                  : "w-full h-full"
            }`}
            alt="Feature visualization"
            src={contentImage}
          />
        </div>
      </div>
      {decorationImage && (
        <img
          className="absolute top-[-55px] right-[-25px] sm:right-[-80px] w-[227px] h-auto z-10"
          src={decorationImage}
        />
      )}
    </div>
  );
};
