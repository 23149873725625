import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  HomePage,
  NotFound,
  FAQsPage,
} from "@/pages";
import { LandingLayout, ContactSalesModal } from "@/components";
import { AppProvider } from "./hooks";
import Intercom from "@intercom/messenger-js-sdk";

export default () => {
  Intercom({
    app_id: "itnqa49d",
  });

  return (
    <BrowserRouter>
      <AppProvider>
        <Routes>
          <Route
            path="/"
            element={
              <LandingLayout>
                <HomePage />
              </LandingLayout>
            }
          />

          <Route path="/404" element={<NotFound />} />

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>

        <ContactSalesModal />
      </AppProvider>
    </BrowserRouter>
  );
};
