import { cn } from "@/lib/utils";
import React, { forwardRef } from "react";

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
}

export const Section = forwardRef<HTMLElement, SectionProps>(
  ({ children, className, style, id, ...props }, ref) => {
    return (
      <section
        ref={ref}
        style={style}
        id={id}
        className={cn(
          "flex flex-wrap justify-center items-center py-10 md:py-16 w-full px-5 mx-auto",
          className
        )}
        {...props}
      >
        {children}
      </section>
    );
  }
);

Section.displayName = "Section";
