import clsx from "clsx";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

type DescriptionProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    size?: "md" | "sm" | "lg" | "xl";
  }
>;
export const Description: FC<DescriptionProps> = ({
  className,
  children,
  size = "md",
  ...props
}) => {
  return (
    <div
      className={clsx(
        "font-inter leading-light font-medium",
        {
          "text-[12px] md:text-[16px]": size === "sm",
          "text-[16px] md:text-[18px]": size === "md",
          "text-[18px] md:text-[20px]": size === "lg",
          "text-[20px] md:text-[24px]": size === "xl",
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
