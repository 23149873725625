import React from "react";
import { MdArrowForward } from "react-icons/md";
import { Badge, Button, Section, Text } from "@/components";
import { useApp } from "@/hooks";


export const PricingSection = (): JSX.Element => {
  const { setShowContactSalesModal, doGetStarted } = useApp();
  // Pricing plan data
  const pricingPlans = [
    {
      price: "$700",
      period: "month",
      buttonText: "Get Essential",
      features: ["25 Deals/month", "1 Seat"],
    },
    {
      price: "$1200",
      period: "month",
      buttonText: "Get Professional",
      features: ["50 Deals/month", "3 Seats"],
    },
    {
      price: "$12,000",
      period: "year",
      buttonText: "Get Premier",
      features: ["75 Deals/month", "7 Seats"],
    },
  ];

  // Enterprise features
  const enterpriseFeatures = [
    ["100+ Deals/Months", "10+ Seats", "Custom Prompt Training"],
    ["Custom Integrations", "White Glove On Premise Deployment"],
  ];

  return (
    <Section id="pricing-section" className="flex-col bg-white gap-10 md:gap-16">
      <div className="max-w-4xl mx-auto">
        <div className="flex flex-col items-center gap-10 w-full">
          <div className="flex flex-col items-center gap-4">
            <h2 className="font-semibold text-[33px] sm:text-[44px] text-black text-center">
              Simple, Scalable Pricing
            </h2>

            <Badge
              className="px-[17px] py-2.5 rounded-[100px] border border-solid border-[#c9c9c9] bg-[#F2F2F2]"
              variant="outline"
            >
              <span className="font-semibold text-black">
                14 day free trial&nbsp;
              </span>
              <span className="text-black font-normal">
                included on all packages
              </span>
            </Badge>
          </div>

          <div className="flex flex-col items-start gap-5 w-full">
            <div className="flex flex-wrap items-center justify-center gap-2 w-full">
              {pricingPlans.map((plan, index) => (
                <div
                  key={index}
                  className="w-[260px] h-60 border border-solid border-[#E1E1E1] rounded-lg text-center"
                >
                  <div className="flex flex-col items-center justify-center h-full p-5 gap-2.5">
                    <div className="flex flex-col items-center gap-5">
                      <div className="flex items-end gap-[3px]">
                        <span className="font-semibold text-black text-3xl tracking-[-0.23px] leading-[34px]">
                          {plan.price} /{" "}
                        </span>
                        <span className="font-medium text-black text-sm">
                          {plan.period}
                        </span>
                      </div>

                      <Button
                        className="pricing-option-btn w-[174px] h-9 flex items-center justify-between px-4 py-2.5 rounded-md"
                        onClick={doGetStarted}
                      >
                        <span className="font-medium text-sm tracking-[0.21px]">
                          {plan.buttonText}
                        </span>
                        <img src="/images/home/arrow-right.svg" alt="Arrow right" className="w-5 h-5" />
                      </Button>
                    </div>

                    <div className="flex flex-col items-start justify-center gap-1 mt-2">
                      {plan.features.map((feature, featureIndex) => (
                        <div
                          key={featureIndex}
                          className="flex items-center gap-[13px] w-full"
                        >
                          <img src="/images/home/tick.svg" alt="Check icon" className="w-5 h-5" />
                          <span className="text-black text-[14px] leading-7">
                            {feature}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full bg-[#e5fdff] border-[#2dc6cc] shadow-primary-drop-shadow">
              <div className="flex flex-col items-center gap-4 p-5">
                <div className="flex flex-col items-center gap-3 w-full">
                  <Badge className="w-[152px] flex justify-center text-[#334155] text-[14px] px-[17px] py-1 bg-[#f8f8f8] rounded-[100px] border border-solid border-slate-300">
                    Enterprise
                  </Badge>

                  {enterpriseFeatures.map((row, rowIndex) => (
                    <div
                      key={rowIndex}
                      className="flex flex-wrap items-start gap-3"
                    >
                      {row.map((feature, featureIndex) => (
                        <div
                          key={featureIndex}
                          className="flex items-center gap-1"
                        >
                          <img src="/images/home/tick.svg" alt="Check icon" className="w-5 h-5" />
                          <Text.Description className="font-medium text-[#1a1a1a] text-sm tracking-[0.21px] leading-5">
                            {feature}
                          </Text.Description>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <Button
                  className="w-full rounded-md"
                  onClick={() => {
                    window.open('https://calendly.com/dealroom-sales/30min', '_blank');
                  }}
                >
                  <Text.Description className="font-semibold text-sm tracking-[0.21px]">
                    Ask Us For a Quote
                  </Text.Description>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-center gap-3 mt-[40px]">
          <Button
            className="h-[60px] min-w-[280px]"
            onClick={doGetStarted}
          >
            <span className="font-semibold text-base text-center leading-[27px]">
              Get Started Today
            </span>
          </Button>

          <Button
            secondary
            className="h-[60px] min-w-[280px] rounded"
            onClick={() => setShowContactSalesModal(true)}
          >
            Contact Sales
          </Button>
        </div>
      </div>
    </Section>
  );
};
